import { twMerge } from "tailwind-merge";
import jsPDF from "jspdf";

import { centerPointsCols } from "./StepProblems";
import { marketingResearchesCols } from "./StepMarketResearch";
import { feasibilityAnalysisCols } from "./StepForecastAnalysis";
import { solutionsCols } from "./StepSolution";
import { ODsImages } from "./contants";
import { environmentalResponsibilityCols } from "./StepResponsibility";
import { riskAnalysisCols } from "./StepRiskAnalysis";

import image1 from "../../assets/step_01.png";
import image2 from "../../assets/step_02.png";
import image3 from "../../assets/step_03.png";
import image4 from "../../assets/step_04.png";
import image5 from "../../assets/step_05.png";
import image6 from "../../assets/step_06.png";
import image7 from "../../assets/step_07.png";
import image8 from "../../assets/step_08.png";

import { useRef } from "react";
import { conclusionCols } from "./StepConclusion";

const PrintScreenList = ({ canva }) => {
  const title = `Licita Canvas - ${canva?.name}`;
  const canvaTeam = {
    projectOrigin: canva?.projectOrigin,
    projectManager: canva?.projectManager,
    technicalManager: canva?.technicalManager,
    estimator: canva?.estimator,
    editor: canva?.editor,
    legal: canva?.legal,
    assistants: canva?.assistants,
    expenseOrganizer: canva?.expenseOrganizer,
    approvers: canva?.approvers,
    experts: canva?.experts,
    market: canva?.market,
    influencers: canva?.influencers,
    financial: canva?.financial,
  };
  const centerPoints = canva?.centerPoints;
  const marketingResearches = canva?.marketingResearches;
  const feasibilityAnalysis = canva?.feasibilityAnalysis;
  const solutions = canva?.solution;
  const environmentalResponsibility = canva?.environmentalResponsibility;
  const sustainableGoals = canva?.sustainableGoals;
  const riskAnalysis = canva?.riskAnalysis;
  const conclusion = canva?.conclusion;

  const jsPdf = new jsPDF("p", "pt", "a4", true, true);
  var width = jsPdf.internal.pageSize.getWidth();

  const printDivRef = useRef(null);

  return (
    <div
      ref={printDivRef}
      className="px-6 tracking-wide"
      style={{ width: width - 40 }}
    >
      <h1 className="text-2xl text-center pb-8">{title}</h1>
      <div className="space-y-8">
        <div className="border rounded-md px-3 py-4">
          <CanvaTitleItem
            title="Pessoas"
            src={image1}
            className="bg-yellow-canva border-yellow-canva"
          />
          <CanvaListItem title="Origem">
            <p>{canvaTeam?.projectOrigin}</p>
          </CanvaListItem>
          <div>
            <CanvaTitleItem
              title="Equipe de planejamento"
              className="mt-2 border-yellow-canva"
            />
            <div>
              <CanvaListItem title="Gerente de Projeto">
                <p>{canvaTeam?.projectManager}</p>
              </CanvaListItem>
              <CanvaListItem title="Responsável Técnico">
                <p>{canvaTeam?.technicalManager}</p>
              </CanvaListItem>
              <CanvaListItem title="Orçamentista">
                <p>{canvaTeam?.estimator}</p>
              </CanvaListItem>
              <CanvaListItem title="Redator">
                <p>{canvaTeam?.editor}</p>
              </CanvaListItem>
              <CanvaListItem title="Jurídico">
                <p>{canvaTeam?.legal}</p>
              </CanvaListItem>
              <CanvaListItem title="Auxiliares">
                <p>{canvaTeam?.assistants}</p>
              </CanvaListItem>
            </div>
          </div>
          <CanvaTitleItem
            title="Stakeholders"
            className="mt-2 border-orange-canva"
          />
          <CanvaListItem title="Ordenador de Despesas">
            <p>{canvaTeam?.expenseOrganizer}</p>
          </CanvaListItem>
          <CanvaListItem title="Aprovadores">
            <p>{canvaTeam?.approvers}</p>
          </CanvaListItem>
          <CanvaListItem title="Especialistas">
            <p>{canvaTeam?.experts}</p>
          </CanvaListItem>
          <CanvaListItem title="Mercado">
            <p>{canvaTeam?.market}</p>
          </CanvaListItem>
          <CanvaListItem title="Influenciadores">
            <p>{canvaTeam?.influencers}</p>
          </CanvaListItem>
          <CanvaListItem title="Financeiros">
            <p>{canvaTeam?.financial}</p>
          </CanvaListItem>
        </div>
        <div className="border rounded-md px-3 py-4">
          <CanvaTitleItem
            title="Pontos Centrais"
            src={image2}
            className="bg-blue-canva border-blue-canva"
          />
          {centerPointsCols.slice(0, 4)?.map((col) => (
            <CanvaPostItWrapper key={col.key} title={col?.name}>
              {centerPoints?.[col?.key]?.map((item) => (
                <CanvaPostItItem key={item.id} item={item} />
              ))}
            </CanvaPostItWrapper>
          ))}
          <CanvaTitleItem
            title="Requisitos da aquisição/contratação"
            className="mt-2 border-blue-canva"
          />
          {centerPointsCols.slice(4, 8)?.map((col) => (
            <CanvaPostItWrapper key={col.key} title={col?.name}>
              {centerPoints?.[col?.key]?.map((item) => (
                <CanvaPostItItem key={item.id} item={item} />
              ))}
            </CanvaPostItWrapper>
          ))}
        </div>
        <div className="border rounded-md px-3 py-4">
          <CanvaTitleItem
            title="Pesquisa Mercadológica"
            src={image3}
            className="bg-violet-canva border-violet-canva"
          />
          {marketingResearches?.map((marketingResearch, index) => {
            return marketingResearchesCols?.map((col) => {
              return (
                <CanvaPostItWrapper
                  key={col.key}
                  title={`${col?.name} ${index + 1}`}
                >
                  {marketingResearch?.[col?.key]?.map((item) => (
                    <CanvaPostItItem key={item.id} item={item} />
                  ))}
                </CanvaPostItWrapper>
              );
            });
          })}
        </div>
        <div className="border rounded-md px-3 py-4">
          <CanvaTitleItem
            title="Análise de Viabilidade"
            src={image4}
            className="bg-red-canva border-red-canva"
          />
          {feasibilityAnalysisCols.map((col) => {
            return (
              <CanvaPostItWrapper key={col.key} title={col?.name}>
                {feasibilityAnalysis?.[col?.key]?.map((item) => (
                  <CanvaPostItItem key={item.id} item={item} />
                ))}
              </CanvaPostItWrapper>
            );
          })}
        </div>
        <div className="border rounded-md px-3 py-4">
          <CanvaTitleItem
            title="Solução"
            src={image5}
            className="bg-green-canva border-green-canva"
          />
          {solutionsCols.map((col) => {
            return (
              <CanvaPostItWrapper key={col.key} title={col?.name}>
                {solutions?.[col?.key].map((item) => (
                  <CanvaPostItItem key={item.id} item={item} />
                ))}
              </CanvaPostItWrapper>
            );
          })}
        </div>
        <div className="border rounded-md px-3 py-4">
          <CanvaTitleItem
            title="Responsabilidade ambiental"
            src={image6}
            className="bg-dark-green-canva border-dark-green-canva"
          />
          <CanvaPostItWrapper title="ODS alcançados pelo projeto">
            <div className="flex gap-2 flex-wrap">
              {sustainableGoals?.map((sustainableGoal) => (
                <p>
                  <img
                    key={sustainableGoal.id}
                    className="mt-2 w-20"
                    src={ODsImages?.[sustainableGoal?.fileName]}
                    alt={sustainableGoal?.title}
                  />
                </p>
              ))}
            </div>
          </CanvaPostItWrapper>
          {environmentalResponsibilityCols?.map((col) => (
            <CanvaPostItWrapper key={col.key} title={col?.name}>
              {environmentalResponsibility?.[col?.key]?.map((item) => (
                <CanvaPostItItem key={item.id} item={item} />
              ))}
            </CanvaPostItWrapper>
          ))}
        </div>
        <div className="border rounded-md px-3 py-4">
          <CanvaTitleItem
            title="Análise de riscos"
            src={image7}
            className="bg-pink-canva border-pink-canva"
          />
          {riskAnalysisCols.map((col) => (
            <CanvaPostItWrapper key={col.key} title={col?.name}>
              {riskAnalysis?.[col?.key]?.map((item) => (
                <CanvaPostItItem key={item.id} item={item} />
              ))}
            </CanvaPostItWrapper>
          ))}
        </div>
        <div className="border rounded-md px-3 py-4">
          <CanvaTitleItem
            title="Conclusão"
            src={image8}
            className="bg-magic-mint border-magic-mint"
          />
          {conclusionCols.map((col) => (
            <CanvaPostItWrapper key={col.key} title={col?.name}>
              {conclusion?.[col?.key]?.map((item) => (
                <CanvaPostItItem key={item.id} item={item} />
              ))}
            </CanvaPostItWrapper>
          ))}
          <CanvaListItem title="Posicionamento Conclusivo">
            <p className="text-justify">
              {canvaTeam?.conclusivePositioning ||
                `${"Após todo o estudo realizado neste projeto, concluiu-se que a solução indicada no tópico 5 atende às necessidades da Administração Pública previstas no tópico 2, conforme análises de viabilidade contidas no tópico 4 e demais justificativas contidas no presente documento. \nO Licita Canvas elaborado pela Equipe de Planejamento é parte integrante deste ETP, bem como todos os demais documentos que o dão suporte, como pesquisas mercadológicas, memórias de cálculo, estimativas, previsões orçamentárias e demais documentos anexos."}`}
            </p>
          </CanvaListItem>
        </div>
      </div>
    </div>
  );
};

function CanvaPostItItem({ item }) {
  return (
    <div
      className="px-2 rounded-md pb-3.5"
      style={{ backgroundColor: item?.bgColor }}
    >
      <p className="whitespace-pre-line text-justify">{item?.content}</p>
    </div>
  );
}

function CanvaPostItWrapper({ className, title, children }) {
  return (
    <div className={twMerge("mt-3 rounded-md pl-1", className)}>
      <p className="text-sm font-bold pl-1 pb-2">{title}</p>
      <div className="mt-2.5 space-y-3 rounded-md min-h-[38px]">{children}</div>
    </div>
  );
}

function CanvaListItem({ className, title, children }) {
  return (
    <div className={twMerge("mt-3 rounded-md px-2", className)}>
      <p className="flex items-center text-sm font-bold m-0">{title}</p>
      <div className="mt-2.5 border border-gray-400 px-2 rounded-md min-h-[38px] pb-3">
        {children}
      </div>
    </div>
  );
}

function CanvaTitleItem({ title, className, src }) {
  return (
    <div className="flex items-center mt-2 justify-between">
      <div
        className={twMerge(
          "flex-1 rounded-md mr-2 px-2 border-l-8 pb-3.5",
          className
        )}
      >
        <h6 className="font-bold">{title}</h6>
      </div>
      {src && (
        <div className="h-8 w-8">
          <img src={src} alt="step1" />
        </div>
      )}
    </div>
  );
}

export default PrintScreenList;
