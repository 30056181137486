import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import {
  DndContext,
  DragOverlay,
  closestCorners,
  KeyboardSensor,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
} from "@dnd-kit/core";

import PostItContainer from "./PostItContainer";
import { PostIt } from "./SortablePostIt";
import ModalPostIt from "../ModalPostIt";
import ModalDeletePostIt from "../ModalDeletePostIt";

export default function DragAndDropPostIt({
  containerStyle,
  createCanvaCard,
  updateCanvaCard,
  deleteCanvaCard,
  canvaId,
  marketingResearchId,
  disabled,
  loading,
  cols = [],
  colsCards,
}) {
  const [items, setItems] = useState({});
  const [activeItem, setActiveItem] = useState();
  const [opentDeletePostItModal, setOpenDeletePostItModal] = useState(false);
  const [openPostItModal, setOpenPostItModal] = useState(false);
  const [selectedPostItToEdit, setSelectedPostItToedit] = useState(null);
  const [cardKindToAdd, setCardKindToAdd] = useState(null);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 5,
        delay: 100,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function findContainer(id) {
    if (Object.keys(items).includes(id)) {
      return id;
    }

    return Object.keys(items).find((key) =>
      items[key].some((item) => item.id === id)
    );
  }

  function handleDragStart(event) {
    const { active } = event;
    setActiveItem(active.data.current);
  }

  function handleDragOver(event) {
    const { active, over } = event;
    const activeContainer = findContainer(active.id);
    const overContainer = findContainer(over.id);

    if (!overContainer || activeContainer === overContainer) {
      return;
    }

    setItems((prevItems) => {
      const activeItems = prevItems[activeContainer];
      const overItems = prevItems[overContainer];

      const activeIndex = activeItems.findIndex(
        (item) => item.id === active.id
      );
      const overIndex = overItems.findIndex((item) => item.id === over.id);

      let newActiveItems = [...activeItems];
      let newOverItems = [...overItems];

      if (overIndex === -1) {
        newOverItems = [...newOverItems, activeItems[activeIndex]];
      } else {
        newOverItems = [
          ...newOverItems.slice(0, overIndex),
          activeItems[activeIndex],
          ...newOverItems.slice(overIndex),
        ];
      }

      newActiveItems.splice(activeIndex, 1);

      return {
        ...prevItems,
        [activeContainer]: newActiveItems,
        [overContainer]: newOverItems,
      };
    });
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    const activeContainer = findContainer(active.id);
    const overContainer = findContainer(over.id);

    if (!overContainer) {
      setActiveItem(null);
      return;
    }

    setItems((prevItems) => {
      const activeItems = prevItems[activeContainer];
      const overItems = prevItems[overContainer];

      const activeIndex = activeItems.findIndex(
        (item) => item.id === active.id
      );
      const overIndex = overItems.findIndex((item) => item.id === over.id);

      let newActiveItems = [...activeItems];
      let newOverItems = [...overItems];

      if (activeContainer === overContainer) {
        newOverItems = arrayMove(newOverItems, activeIndex, overIndex);
      } else {
        if (overIndex === -1) {
          newOverItems = [...newOverItems, activeItems[activeIndex]];
        } else {
          newOverItems = [
            ...newOverItems.slice(0, overIndex),
            activeItems[activeIndex],
            ...newOverItems.slice(overIndex),
          ];
        }
        newActiveItems.splice(activeIndex, 1);
      }

      const newOrder = overIndex >= 0 ? overIndex + 1 : 1;
      const kind = cols.find((col) => col.key === overContainer)?.kind;

      updatePostItOrder(active.id, kind, newOrder);

      return {
        ...prevItems,
        [activeContainer]: newActiveItems,
        [overContainer]: newOverItems,
      };
    });

    setActiveItem(null);
  }

  const updatePostItOrder = async (cardId, kind, newOrder) => {
    const body = {
      cardId,
      kind,
      order: newOrder,
    };

    const response = await updateCanvaCard({ variables: { input: body } });
    const errors = response?.data?.updateCanvaCard?.errors;

    if (errors) {
      toast.error("Não foi possível mover o post-it!");
      return;
    }

    toast.success("Post-it alterado com sucesso!");
  };

  const handleAddPostIt = (kind) => {
    setCardKindToAdd(kind);
    setOpenPostItModal(true);
  };

  const handleEditPostIt = (item) => {
    setSelectedPostItToedit(item);
    setOpenPostItModal(true);
  };

  const handleDeletePostIt = (item) => {
    setSelectedPostItToedit(item);
    setOpenDeletePostItModal(true);
  };

  const handleSubmitPostIt = async (values) => {
    const body = {
      canvaId,
      kind: cardKindToAdd,
      marketingResearchId,
      ...values,
    };

    const response = await createCanvaCard({ variables: { input: body } });

    const errors = response?.data?.createCanvaCard?.erros;

    setOpenPostItModal(false);

    if (errors) {
      toast.error("Não foi possível adicionar o post-it!");
      return;
    }

    toast.success("Post-it adicionado com sucesso!");
  };

  const handleUpdatePostIt = async (values) => {
    const body = {
      cardId: values?.id,
      kind: selectedPostItToEdit?.kind,
      ...values,
    };

    delete body.id;

    const response = await updateCanvaCard({ variables: { input: body } });
    const errors = response?.data?.updateCanvaCard?.erros;

    setOpenPostItModal(false);
    setSelectedPostItToedit(null);

    if (errors) {
      toast.error("Não foi possível editar o post-it!");
      return;
    }
    toast.success("Post-it editado com sucesso!");
  };

  const handleConfirmDeletePostIt = async () => {
    const response = await deleteCanvaCard({
      variables: {
        input: {
          id: selectedPostItToEdit?.id,
        },
      },
    });
    const errors = response?.data?.deleteCard?.errors;

    if (errors) {
      toast.error("Não foi possível deleter o post-it!");
      return;
    }

    setOpenDeletePostItModal(false);
    setSelectedPostItToedit(null);
    toast.success("Post-it deletado com sucesso!");
  };

  useEffect(() => {
    const { __typename, id, order, ...colsCardsWithoutTypename } = colsCards;

    setItems(colsCardsWithoutTypename);
  }, [colsCards]);

  return (
    <div className={`mt-3 grid gap-2 rounded-lg p-2 ${containerStyle}`}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        {cols?.map(({ key }) => {
          return (
            <PostItContainer
              key={key}
              id={key}
              items={items?.[key] || []}
              column={cols.find((col) => col.key === key)}
              onButtonClick={handleAddPostIt}
              onDeletePostIt={handleDeletePostIt}
              onEditPostIt={handleEditPostIt}
              disabled={disabled}
            />
          );
        })}
        {/* {Object.keys(items || {}).map((key) => (
          <PostItContainer
            key={key}
            id={key}
            items={items[key]}
            column={cols.find((col) => col.key === key)}
            onButtonClick={handleAddPostIt}
            onDeletePostIt={handleDeletePostIt}
            onEditPostIt={handleEditPostIt}
            disabled={disabled}
          />
        ))} */}
        <DragOverlay>
          {activeItem ? <PostIt item={activeItem} /> : null}
        </DragOverlay>
      </DndContext>
      <ModalPostIt
        initialValues={selectedPostItToEdit}
        handleSubmitPostIt={handleSubmitPostIt}
        handleUpdatePostIt={handleUpdatePostIt}
        open={openPostItModal}
        onClose={() => {
          setSelectedPostItToedit(null);
          setOpenPostItModal(false);
        }}
        loading={loading}
      />
      <ModalDeletePostIt
        open={opentDeletePostItModal}
        onClose={() => {
          setSelectedPostItToedit(null);
          setOpenDeletePostItModal(false);
        }}
        handleConfirmDeletePostIt={handleConfirmDeletePostIt}
        loading={loading}
      />
    </div>
  );
}
